.page-main {
  max-width:none;
  margin:0;
  display:flex;
  padding:0;

  .columns {
    width:100%;

    .sidebar-main {
      height:100%;
      background: #263B47;
      max-width:230px;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .header-info {
      .close {
        position: absolute;
        top: 11px;
        right: 6px;
        font-size: 20px;
        line-height: 0;
        cursor: pointer;
      }
    }
  }
}
.sidebar-main+.column.main {
  margin-left: 230px;
}
.account-nav {
  .content {
    background: #263B47;
    width:230px;
    padding:0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;

    .customer-account_logo__home {
      padding:30px 0;
      text-align:center;
    }
    .item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      list-style: none;
      a, strong {
        padding: 10px 10px 10px 48px;
        font-size: 14px;
        font-weight: 700;
        color: #F0F2F4;
        text-decoration: none;
        text-transform: uppercase;
        text-align: left;
        border-bottom: none;
        border-left:0;
        display: block;
        position: relative;
        cursor: pointer;
        span {
          display: block;
          font-size: 24px;
          color: #fff;
          opacity: 0.2;
          position: absolute;
          left: 14px;
          top: 10px;
        }
        &:hover {
          background: #000;
          color: #fff;
          opacity: 1;
        }
      }
      &.current {
        a, strong {
          color: #29A9FC;
          background: #000;

          &:hover {
            background: #000;
            color: #fff;
            opacity: 1;
          }
          span {
            color: #29A9FC;
            opacity: 1;
          }
        }
      }
    }
    .item:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
    .item.nav-item-logout {
      padding: 5px;
      border: 0;
      text-align: center;
      a, strong {
        background: transparent !important;
        padding: 0;
        display: inline-block;
        button {
          line-height: 20px;
        }
      }
        &:hover {
          background: #263B47;
          color: #fff;
          opacity: 1;
        }
    }
    .sub {
      div.item {
        position: relative;

        &:before {
          content: '';
          display:inline-block;
          position:absolute;
          border-width: 9px 5px 0 5px;
          border-style: solid;
          border-color: transparent;
          border-top-color: #FFFFFF;
          top: 19px;
          left: 19px;
          z-index:1;
        }
      }
      ul {
        display:none;

        .item {
          background: #304b58;
        }
      }
      &.active ul {
        display:block;
      }
    }
  }
}

#maincontent {
  .header-info,
  .sidebar.sidebar-main,
  .page-functions {
    &.hidden-header {
      display: none !important;
    }
    &.show-op {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 1170px) {
  .account-nav .content {
    width:130px;

    .item {
      a, strong {
        padding: 7px 5px;
        text-align: center;
        font-size: 12px;
        display: block;
        line-height: 17px;
        color: rgba(255, 252, 255, 0.2);

        span {
          font-size: 30px;
          padding-bottom: 4px;
          color: #FFFFFF;
          opacity: 1;
          position: relative;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .page-main {
    .columns {
      .sidebar-main {
        position: absolute;

        &+.column.main {
          margin-left:130px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px), print {
  .page-main .columns .sidebar-main {
    width: 100%;
    height: 45px;
    max-width: none;
    z-index: 21;
    &+.column.main {
      margin-left:0;
    }
  }

  .page-main {
    .columns {
      .header-info {
        & + .sidebar {
          padding-top:0;
          top:52px;

          .content {
            padding-top:0;
          }
        }
      }
    }
  }

  body.nav-is-visible {
    overflow: hidden;

    .account-nav {
      position: fixed;
      overflow: auto;
      height: calc(100% - 45px);
      width: 100%;
    }
  }

  .account-nav {
    position:relative;
    top:45px;

    .content {
      visibility: hidden;
      opacity: 0;
      max-height: 100vh;
      overflow: hidden;
      display:none;
      width: 100%;
      z-index: 21;
      position:relative;

      .customer-account_logo__home {
        display: none;
      }

      &.nav-is-visible {
        opacity: 1;
        display:block;
        visibility: visible;
        overflow: visible;
        -webkit-overflow-scrolling: touch;
        -webkit-transition: opacity 0.2s 0s, visibility 0s 0s;
        -moz-transition: opacity 0.2s 0s, visibility 0s 0s;
        transition: opacity 0.2s 0s, visibility 0s 0s;
        max-height: none;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      }

      .item {
        a, strong {
          font-size: 1.4rem;
          text-decoration: none;
          text-transform: none;
          font-weight: 400;
          padding: 1em 5%;
          text-align: left;
          color: #FFFFFF;

          span {
            font-size: 100%;
            opacity: 0.2;
            display: inline-block;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px), print {
  .page-main {
    .columns {
      .header-info + .sidebar {
        top: 75px;
      }
    }
  }
}